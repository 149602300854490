export function useAuthLogout() {
  const profile = useProfile()
  const fetched = useState('dev-claims-fetched', () => false)

  return async () => {
    await api.auth.logout()
    profile.value = null
    useCookie('token').value = null
    useCookie('refresh-token').value = null
    fetched.value = false
  }
}
